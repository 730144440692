<template>
  <div class="content flex flex-col gap-4 max-w-2xl">
    <div>
      <table class="table table-google">
        <thead>
          <tr>
            <th colspan="2">
              <div class="flex gap-4 justify-between items-center">
                <div>Label</div>
                <div class="flex gap-2">
                  <b-button
                    variant="white"
                    size="sm"
                    @click="openData"
                  >
                    Data
                  </b-button>

                  <printer-select />
                </div>
              </div>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Label size</td>
            <td>
              <button-select
                v-model="label.label_type"
                :options="options.label_type"
                size="sm"
              >
              </button-select>
            </td>
          </tr>
          <tr>
            <td>Orientation</td>
            <td>
              <button-select
                v-model="label.portrait_mode"
                :options="options.portrait_mode"
                size="sm"
              >
              </button-select>
            </td>
          </tr>

        </tbody>
      </table>
    </div>

    <div class="flex gap-4 justify-between">
      <div class="flex gap-2 items-center flex-1">
         <b-form-select
            v-model="fieldOption"
            :options="fieldOptions"
            size="sm"
            :disabled="fieldOptions.length === 0"
          ></b-form-select>
          <b-button
            @click="createField"
            variant="primary"
            size="sm"
          >
            Add
          </b-button>
          <b-button
            @click="deleteField"
            variant="danger"
            size="sm"
            :disabled="fieldOption === null"
          >
            Delete
          </b-button>
          <b-button
            @click="duplicateField"
            variant="white"
            size="sm"
            :disabled="fieldOption === null"
          >
            Duplicate
          </b-button>
      </div>
    </div>

    <raw-field
      v-if="field"
      v-model="field"
    />

    <label-preview-floater
      :label="label"
      :validate="false"
      printEndpoint="/label_raw_print"
      previewEndpoint="/label_raw_preview"
    />

    <data-modal
      v-if="dataModal.showModal"
      :data="dataModal"
      @import="importData"
    />
  </div>
</template>

<script>
import { v4 as uuidv4 } from 'uuid';

const ButtonSelect = () => import('@/components/global/ButtonSelect.vue');
const PrinterSelect = () => import('@/components/labels/PrinterSelect.vue');
const RawField = () => import('@/components/label_raw/RawField.vue');
const DataModal = () => import('@/components/label_raw/DataModal.vue');
const LabelPreviewFloater = () => import('@/components/labels/LabelPreviewFloater.vue');

export default {
  name: 'LabelRaw',
  components: {
    ButtonSelect,
    PrinterSelect,
    RawField,
    DataModal,
    LabelPreviewFloater,
  },
  computed: {
    field() {
      if (this.fieldOption === null) {
        return null;
      }
      return this.label.fields.find((field) => field.uuid === this.fieldOption);
    },
    fieldOptions() {
      return this.label.fields
        .map((field) => ({
          text: field.field_name.length > 0 ? field.field_name : field.uuid,
          value: field.uuid,
        }))
        .sort((a, z) => a.text.localeCompare(z.text));
    },
  },
  data() {
    return {
      fieldOption: null,
      label: {
        label_type: 33,
        portrait_mode: false,
        fields: [],
      },
      options: {
        label_type: [
          { text: 'SLP-SRL', value: 2 },
          { text: 'SLP-STAMP2', value: 33 },
        ],
        portrait_mode: [
          { text: 'Portrait', value: true },
          { text: 'Landscape', value: false },
        ],
      },
      dataModal: {
        showModal: false,
        label: null,
      },
    };
  },
  methods: {
    openData() {
      this.dataModal.showModal = true;
      this.dataModal.label = this.label;
    },
    importData(data) {
      this.label = data;
    },
    createField() {
      const field = {
        uuid: uuidv4(),
        field_name: '',
        field_type: 'text',
        field_direction: 'ltr',
        title: '',
        text: '',
        text_dots: true,
        text_title: true,
        x1: 0,
        x2: 0,
        y1: 0,
        y2: 0,
        thickness: 1,
        barcode_type: 7,
        barcode_text: true,
        font_size: 9,
        font_bold: false,
        font_italic: false,
        font_underline: false,
      };
      this.label.fields.push(field);
      this.fieldOption = field.uuid;
    },
    deleteField() {
      if (!this.field) {
        return;
      }
      const fieldName = this.field.field_name.length > 0 ? this.field.field_name : this.field.uuid;
      if (!confirm(`Do you really wish to delete '${fieldName}' field?`)) {
        return;
      }
      const fieldIndex = this.fieldOptions.findIndex((field) => field.value === this.fieldOption);
      this.label.fields = this.label.fields.filter((field) => field.uuid !== this.fieldOption);
      this.fieldOption = (
        this.fieldOptions.length > 0
          ? this.fieldOptions[Math.min(this.fieldOptions.length - 1, fieldIndex)].value
          : null
      );
    },
    duplicateField() {
      const duplicateField = JSON.parse(JSON.stringify(this.field));
      duplicateField.uuid = uuidv4();
      duplicateField.field_name = '';
      this.label.fields.push(duplicateField);
      this.fieldOption = duplicateField.uuid;
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
